/* Button.js */
.glBtn {
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
  text-align: center;
  overflow: hidden;
}
.circle {
  border-radius: 100%;
}
.noBorder {
  border: unset;
}
.glDate {
  text-align: left;
}
.cstmBtn {
  padding: 0;
  border: none;
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
  text-align: center;
  overflow: hidden;
}

/* FormSelect.js */
:global {
  .cst-select {
    &.form-select {
      padding-right: 0 !important;
    }
  }
  input::-webkit-date-and-time-value {
    text-align: left;
  }
  .my-loading-wrapper {
    position: fixed;
    z-index: 9999;
    top: 0px;
    left: 0px;
    width: 100dvw;
    height: 100dvh;
    text-align: center;
    background: #42424247;
    margin: 0px;
    padding: 0px;
  }
  .my-loading-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
  }
}
.glSelect {
  padding-right: 0;
  background-size: 10px 10px;
}

.requireInput {
  background-color: lavenderblush;
}
